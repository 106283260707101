import React from 'react';
import { CmsBenefits, CmsDashboardCta, CmsHelpSection } from '@features/cms';
import type { CmsBenefitsProps } from '@features/cms/components/cms-benefits/types';
import { CmsImage } from '@features/cms/components/cms-image';
import type {
  BannerAdProps,
  CmsContentProps,
  FaqsProps,
} from '@goodfynd/react-web.lib.content';
import { BannerAd, CmsContent, Faqs } from '@goodfynd/react-web.lib.content';
import { ButtonLink } from '@goodfynd/react-web.ui.button';
import { dateUtil } from '@goodfynd/react-web.utils.date-util/dist';

import type { CmsButtonFields, ComponentProps, ComponentType } from './types';

export default function renderCmsBodyComponent(
  index: number,
  componentType: ComponentType,
  fields: unknown,
  { className }: ComponentProps = {}
): JSX.Element | null {
  console.log({ componentType });
  switch (componentType) {
    case 'button':
      const {
        button_color,
        button_href,
        button_rank,
        button_size,
        button_text,
      } = fields as CmsButtonFields;
      return !!button_href && !!button_text ? (
        <ButtonLink
          color={button_color}
          href={button_href}
          rank={button_rank}
          size={button_size}
        >
          {button_text}
        </ButtonLink>
      ) : null;

    case 'benefits':
      return (
        <CmsBenefits
          {...(fields as CmsBenefitsProps)}
          key={componentType + index}
        />
      );

    case 'content_section':
      const contentfields = fields as CmsContentProps;
      return (
        <CmsContent
          {...contentfields}
          className={className}
          header_type={contentfields.header_type || undefined}
          key={componentType + index}
        />
      );

    case 'dashboard_cta':
      return (
        <CmsDashboardCta
          {...(fields as CmsDashboardCtaProps)}
          key={componentType + index}
        />
      );

    case 'help_section':
      return (
        <CmsHelpSection
          {...(fields as CmsHelpSectionProps)}
          key={componentType + index}
        />
      );

    case 'image':
      const imageProps = fields as CmsImageProps;
      return (
        <CmsImage
          {...imageProps}
          className={className}
          key={componentType + index}
        />
      );

    case 'vendor_dashboard_help_section':
      return (
        <CmsHelpSection
          {...(fields as CmsHelpSectionProps)}
          key={componentType + index}
        />
      );

    case 'vendor_dashboard_faqs':
      return <Faqs {...(fields as FaqsProps)} key={componentType + index} />;

    case 'banner_ad':
      const { start_date, end_date, ...props } = fields as BannerAdProps & {
        start_date: Date;
        end_date: Date;
      };
      return !!start_date &&
        !!end_date &&
        dateUtil.isWithinInterval(new Date(), {
          start: dateUtil.startOfDay(start_date),
          end: dateUtil.endOfDay(end_date),
        }) ? (
        <BannerAd {...props} key={componentType + index} />
      ) : null;
    default:
      return null;
  }
}
