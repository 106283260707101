import React, { useCallback } from 'react';
import useCmsContent from '@features/cms/hooks/useCmsContent';
import { renderCmsBodyComponent } from '@features/cms/utils/cms-util';
import { LoadingTruck } from '@goodfynd/react-web.lib.ui';
import { Box } from '@mui/material';

export function CmsContentRenderer({
  className,
  render,
  renderLoading,
  slug,
}: CmsContentProps) {
  const {
    busy,
    fields: { body },
  } = useCmsContent(slug);

  const renderBody = useCallback(
    () => (
      <>
        {body?.map(({ fields, type }, index) =>
          (render || renderCmsBodyComponent)(index, type, fields, {
            className,
          })
        )}
      </>
    ),
    [body, className, render]
  );

  if (busy && renderLoading) {
    return renderLoading();
  } else if (busy) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        <LoadingTruck />
      </Box>
    );
  }

  return renderBody();
}
