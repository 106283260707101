import React from 'react';
import useCmsContent from '@features/cms/hooks/useCmsContent';
import { Loading } from '@goodfynd/react-web.lib.ui';
import { useTheme } from '@goodfynd/react-web.theme';
import { Image } from '@goodfynd/react-web.ui.image';

export function CmsImage({ slug, ...props }: CmsImageProps) {
  const { isDarkMode } = useTheme();

  const {
    busy,
    fields: {
      image: { alt = 'Image', image = '', image_dark = '', ...fields } = {},
    },
  } = useCmsContent(slug);

  if (busy) {
    return <Loading type="line" />;
  }

  if (!fields) {
    return null;
  }

  return (
    <Image
      {...fields}
      {...props}
      alt={alt}
      src={isDarkMode ? image_dark : image}
    />
  );
}
