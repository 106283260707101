import { Image } from '@goodfynd/react-web.lib.ui';
import { styled } from '@goodfynd/react-web.theme';

export const StyledActionContainer = styled('div', {
  display: 'flex',
  marginTop: 24,
});

export const StyledContainer = styled('section', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 32,
});

export const StyledImage = styled(Image, {
  marginBottom: 16,
});
