import { useEffect, useState } from 'react';

import CmsApi from '../../../services/api/cms-api';

import type { CmsFields } from './types';

export default function useCmsContent<TFields = CmsFields>(slug: string) {
  const [busy, toggleBusy] = useState(true);
  const [fields = {} as TFields, setFields] = useState<TFields>();

  useEffect(() => {
    if (slug) {
      (async () => {
        try {
          toggleBusy(true);
          const api = new CmsApi();
          const data = await api.getPageData(slug);
          setFields(data?.fields ?? {});
        } finally {
          toggleBusy(false);
        }
      })();
    }
  }, [slug]);

  return {
    busy,
    fields,
  };
}
